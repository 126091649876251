<template>
   <!-- auth-page wrapper -->
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
      <div class="bg-overlay"></div>
      <!-- auth-page content -->
      <div class="auth-page-content overflow-hidden pt-lg-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="card overflow-hidden">
                <div class="row justify-content-center g-0">
                  <div class="col-lg-6">
                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                      <div class="bg-overlay"></div>
                    </div>
                  </div>
                  <!-- end col -->

                  <div class="col-lg-6">
                    <div class="p-lg-5 p-4 text-center">
                      <lottie
                        colors="primary:#405189,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="180"
                        :width="180"
                        />

                      <div class="mt-4 pt-2">
                        <h5>You are Logged Out</h5>
                        <p class="text-muted">See you later!</p>
                        <div class="mt-4">
                          <router-link to="/login" class="btn btn-info w-100">Sign In</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
  
          </div>
          <!-- end row -->
        </div>
        <!-- end container -->
      </div>
      <!-- end auth page content -->

      <!-- footer -->
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <p class="mb-0">&copy; {{new Date().getFullYear()}} Admin. Crafted with <i class="ri-heart-fill text-danger"></i> by JDMLabs</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>

<script>
import store from "@/state/store"
import animationData from "@/components/widgets/Lootie/animations/greeature.json";
import Lottie from "@/components/widgets/Lootie";

export default {
   data() {
    return {
       defaultOptions: { animationData: animationData },
    }
  },
  components: {
    lottie: Lottie
  },
  mounted() {
    localStorage.clear()
    store.commit('auth/clearUserData')
  }
}
</script>